import React from 'react';

function QAIcon({ className }) {
  return (
    <svg className={className} width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.7874 20.5641C26.6663 19.7097 25.8756 18.4804 25.4096 17.5994C25.2427 17.2838 25.1042 16.9888 24.9934 16.7343H26.0048C27.079 16.7343 27.9531 15.8602 27.9531 14.786V8.16414C27.9531 7.0899 27.0791 6.21582 26.0048 6.21582H20.0825V7.05624H26.0048C26.6157 7.05624 27.1127 7.55324 27.1127 8.16414V14.786C27.1127 15.3969 26.6157 15.8939 26.0048 15.8939H23.8247L23.8328 16.0483L23.8485 16.0984C23.854 16.1165 23.9293 16.3566 24.0817 16.7343C24.2139 17.0624 24.4044 17.4945 24.6579 17.9754C24.9785 18.5839 25.3347 19.1456 25.7164 19.6454C25.8495 19.8195 25.986 19.9864 26.1256 20.1455C25.7454 20.0302 25.3081 19.878 24.8356 19.6782C23.6461 19.1753 22.0219 18.2757 20.5373 16.7343C20.2858 16.4728 20.0382 16.1931 19.7975 15.8939H11.6151C11.0042 15.8939 10.5072 15.3969 10.5072 14.786V12.1232H9.66675V14.786C9.66675 15.8602 10.5408 16.7343 11.6151 16.7343H19.4028C20.1079 17.5594 20.91 18.2966 21.7938 18.9313C22.6409 19.5398 23.5643 20.0556 24.5385 20.4651C26.2027 21.1643 27.4338 21.31 27.4854 21.3159L29.0001 21.4881L27.7874 20.5641Z" fill="currentColor" />
      <path d="M19.3334 9.44597V2.82429C19.3334 1.75006 18.4593 0.875977 17.385 0.875977H2.99548C1.92125 0.875977 1.04717 1.75004 1.04717 2.82429V9.44597C1.04717 10.5202 1.92123 11.3943 2.99548 11.3943H4.00674C3.89595 11.6488 3.75738 11.9439 3.59067 12.2594C3.12474 13.1403 2.33401 14.3697 1.21265 15.2241L0 16.1481L1.51482 15.9758C1.56643 15.97 2.79752 15.8243 4.46179 15.1251C5.43595 14.7156 6.35936 14.1997 7.2065 13.5913C7.82102 13.1498 8.3962 12.6589 8.92632 12.1232C9.15876 11.8887 9.3826 11.6456 9.59726 11.3943H17.385C18.4593 11.3943 19.3334 10.5202 19.3334 9.44597ZM13.0404 9.67464C12.6164 9.67464 12.1127 9.63046 11.8476 9.55988C11.338 9.41957 10.5259 8.86545 9.85512 8.39348C8.25317 8.38657 6.97045 7.14288 6.97045 5.44254C6.97045 3.86094 8.09249 2.39427 9.95694 2.39427C11.4678 2.39427 12.8195 3.55169 12.8195 5.31001C12.8195 6.7803 12.0255 7.82176 10.913 8.21746C11.6742 8.75846 12.4459 9.22008 13.1024 9.46272L13.0404 9.67464Z" fill="currentColor" />
      <path d="M11.9184 5.55754C11.9184 3.70207 10.9377 2.72119 9.78025 2.72119C8.79058 2.72119 7.8894 3.55176 7.8894 5.20412C7.8894 6.72359 8.65164 7.86186 9.75005 8.03495L10.3722 7.8194C10.441 7.87187 10.51 7.92382 10.5794 7.97523C11.3318 7.7321 11.9184 6.94674 11.9184 5.55754Z" fill="currentColor" />
      <path d="M19.0593 14.2506H21.1003V14.0031C20.3758 13.9148 20.2521 13.8441 20.4465 13.2698C20.5702 12.9076 20.7028 12.563 20.8441 12.1654H22.7526C22.9381 12.722 23.1148 13.1638 23.2297 13.5084C23.3534 13.8441 23.2827 13.9325 22.7084 14.0031V14.2506H24.8908V14.0031C24.2635 13.9325 24.1839 13.8618 23.9012 13.0931C23.3446 11.5646 22.7349 9.89466 22.1783 8.38379L21.8956 8.43681L20.0666 13.0754C19.7574 13.8706 19.616 13.9325 19.0594 14.0031V14.2506H19.0593ZM21.8248 9.54125L22.6288 11.8031H20.9766C21.2417 11.1051 21.5421 10.3011 21.8248 9.54125Z" fill="black" />
    </svg>
  );
}

export default QAIcon;