import React from 'react';

function RobotIcon({ className }) {
  return (
    <svg className={className} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 2.45618C9.46024 2.45618 9.83333 2.08308 9.83333 1.62284V1.56112C9.83333 1.10088 9.46024 0.727783 9 0.727783V0.727783C8.53976 0.727783 8.16667 1.10088 8.16667 1.56112V1.62284C8.16667 2.08308 8.53976 2.45618 9 2.45618V2.45618V2.45618Z" fill="currentColor" />
      <path d="M3.16667 8.20174V8.20174C3.16667 6.90943 3.16667 6.26328 3.44454 5.78199C3.62658 5.46669 3.88841 5.20486 4.20371 5.02282C4.685 4.74495 5.33115 4.74495 6.62346 4.74495H9M3.16667 8.20174V8.20174C2.24619 8.20174 1.5 8.94793 1.5 9.86841V10.8561C1.5 11.7765 2.24619 12.5227 3.16667 12.5227V12.5227M3.16667 8.20174V12.5227M3.16667 12.5227V12.5227C3.16667 13.815 3.16667 14.4612 3.44454 14.9425C3.62658 15.2578 3.88841 15.5196 4.20371 15.7016C4.685 15.9795 5.33115 15.9795 6.62346 15.9795H11.3765C12.6689 15.9795 13.315 15.9795 13.7963 15.7016C14.1116 15.5196 14.3734 15.2578 14.5555 14.9425C14.8333 14.4612 14.8333 13.815 14.8333 12.5227V12.5227M14.8333 8.20174V8.20174C14.8333 6.90943 14.8333 6.26328 14.5555 5.78199C14.3734 5.46669 14.1116 5.20486 13.7963 5.02282C13.315 4.74495 12.6689 4.74495 11.3765 4.74495H9M14.8333 8.20174V8.20174C15.7538 8.20174 16.5 8.94793 16.5 9.86841V10.8561C16.5 11.7765 15.7538 12.5227 14.8333 12.5227V12.5227M14.8333 8.20174V12.5227M11.5 12.5227H6.5M9 4.74495V2.45618M9 2.45618V2.45618C8.53976 2.45618 8.16667 2.08308 8.16667 1.62284V1.56112C8.16667 1.10088 8.53976 0.727783 9 0.727783V0.727783C9.46024 0.727783 9.83333 1.10088 9.83333 1.56112V1.62284C9.83333 2.08308 9.46024 2.45618 9 2.45618V2.45618ZM10.875 8.20174V8.20174C11.2202 8.20174 11.5 8.48156 11.5 8.82674V8.87304C11.5 9.21822 11.2202 9.49804 10.875 9.49804V9.49804C10.5298 9.49804 10.25 9.21822 10.25 8.87304V8.82674C10.25 8.48156 10.5298 8.20174 10.875 8.20174ZM7.125 8.20174V8.20174C7.47018 8.20174 7.75 8.48156 7.75 8.82674V8.87304C7.75 9.21822 7.47018 9.49804 7.125 9.49804V9.49804C6.77982 9.49804 6.5 9.21822 6.5 8.87304V8.82674C6.5 8.48156 6.77982 8.20174 7.125 8.20174Z" stroke="currentColor" stroke-width="1.03704" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export default RobotIcon;