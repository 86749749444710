import "plyr/dist/plyr.css";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../constants";
import { useCourseLayoutContext } from "./CourseLayoutProvider";
import CourseVideoLessons from "./CourseVideoLessons/CourseVideoLessons";

function Course() {
  const navigate = useNavigate();

  const { courseData } = useCourseLayoutContext();

  useEffect(() => {
    if (courseData)
      navigate(
        `/${pageRoutes.course}/${pageRoutes.lesson}/${courseData?.lessons[0]?.id}`
      );
  }, [courseData]);

  return (
    <div className="flex  gap-3 md:flex-row flex-col">
      <div className="md:w-[70%] w-[100%]">
        <Outlet />
      </div>
      <CourseVideoLessons />
    </div>
  );
}

export default Course;
