import React from 'react';

function HomeIcon({ className }) {
  return (
    <svg
      className={className}
      width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 12.6353V10.6353"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.71343 2.51523L2.09343 6.21523C1.57343 6.62857 1.2401 7.5019 1.35343 8.15523L2.2401 13.4619C2.4001 14.4086 3.30676 15.1752 4.26676 15.1752H11.7334C12.6868 15.1752 13.6001 14.4019 13.7601 13.4619L14.6468 8.15523C14.7534 7.5019 14.4201 6.62857 13.9068 6.21523L9.28676 2.5219C8.57343 1.94857 7.4201 1.94857 6.71343 2.51523Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HomeIcon;
