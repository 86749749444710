import { ChevronRight } from "@mui/icons-material";
import React, { useState } from "react";
import EyouthLogo from "../../../assets/EYouthLogo.png";
import itidaLogo from "../../../assets/ITIDALogo.png";
import eyouthSmallLogo from "../../../assets/eyouth-small-logo.svg";
import itidaSmallLogo from "../../../assets/itida-small-logo.svg";
import NavigationTabs from "../NavigationTabs/NavigationTabs";

const NavSideBar = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  return (
    <>
      <aside
        className={`sticky top-0 hidden tablet:flex flex-col p-4 border border-gray-100 px-[1rem] pt-[1.5rem] pb-[2rem] shadow-md bg-white h-[100vh] transition-all duration-300 ease-in-out ${
          isSideBarOpen ? "w-56" : "w-20"
        } `}
      >
        <div
          className={`${
            isSideBarOpen ? "" : "flex-wrap"
          } flex items-center gap-x-2 gap-y-3 h-[5rem] mb-5`}
        >
          <div className={`${isSideBarOpen ? "w-[8rem]" : "w-[2rem]"} mx-auto`}>
            <img
              className="cover-image"
              src={isSideBarOpen ? EyouthLogo : eyouthSmallLogo}
              alt="e-youth logo"
            />
          </div>
          <div className={`${isSideBarOpen ? "w-[8rem]" : "w-[2rem]"} mx-auto`}>
            <img
              className="cover-image"
              src={isSideBarOpen ? itidaLogo : itidaSmallLogo}
              alt="ITIDA logo"
            />
          </div>
        </div>

        <nav className="flex flex-col flex-1 pt-1rem gap-3">
          <NavigationTabs isSideBarOpen={isSideBarOpen} />
        </nav>
        <button
          className="absolute top-[50%] translate-y-[-50%] right-[-1rem] w-[2rem] aspect-square rounded-[50%] bg-primary flex items-center justify-center"
          onClick={() => setIsSideBarOpen(!isSideBarOpen)}
        >
          <ChevronRight
            className={`text-white transition-all duration-300 ease-in-out ${
              isSideBarOpen ? "rotate-180" : ""
            }`}
          />
        </button>
      </aside>
    </>
  );
};

export default NavSideBar;
