import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import styles from './Tasks.module.css';
import postDeliverable from '../../../Services/apiPostDeliverable';

export default function SubmitTask({ deliverableId }) {
    const [attachment, setAttachment] = useState(null);
    const [url, setUrl] = useState('');
    const [fileError, setFileError] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === "application/pdf") {
            setAttachment(file);
            setFileError(null); // Clear previous error
        } else {
            setFileError("Only PDF files are allowed.");
            setAttachment(null);
        }
    };

    const handleSubmit = async () => {
        console.log("deliverableId:", deliverableId);
        if (!["Q2", "Q3"].includes(deliverableId)) {
            alert("Invalid deliverable ID. It must be either 'Q2' or 'Q3'.");
            return;
        }

        try {
            await postDeliverable({ deliverableId, attachment, url });
            window.location.reload();
        } catch (error) {
            if (error.response && error.response.data) {
                alert(`Error: ${JSON.stringify(error.response.data)}`);
            } else {
                alert("Error submitting deliverable.");
            }
        }
    };

    return (
        <div className={styles.taskCard}>
            <div className={styles.uploadSection}>
                <div className='border border-[#E5E5E5] rounded-md bg-white px-2 py-2'>
                    <Button
                        variant='outlined'
                        component='label'
                        className='!bg-[#F5F5F5] !border-[#6A6A65] !text-black'
                    >
                        Choose File
                        <input type='file' hidden onChange={handleFileChange} />
                    </Button>
                </div>
                {fileError && <p className={styles.errorText}>{fileError}</p>}
                <TextField
                    fullWidth
                    variant='outlined'
                    placeholder='Enter URL (https://...)'
                    margin='normal'
                    className='bg-white'
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
            </div>
            <Button
                variant='contained'
                color='primary'
                className={styles.submitButton}
                onClick={handleSubmit}
            >
                Submit Task
            </Button>
        </div>
    );
}
