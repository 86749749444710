import FreelanceAccounts from "./FreelanceAccounts/FreelanceAccounts";
import UserInfo from "./UserInfo";

const UserProfileCard = ({ userProfileData, userSystemInfo }) => {
  return (
    <div
      className={`w-[23rem] h-fit ms-auto mr-5 border border-gray-100 px-[1.2rem] py-[1.5rem]  shadow-md mt-[2rem] bg-white rounded-xl sticky top-4 hidden tablet:flex flex-col items-center`}
    >
      <UserInfo
        userProfileData={userProfileData}
        userSystemInfo={userSystemInfo}
      />
      <FreelanceAccounts />
    </div>
  );
};

export default UserProfileCard;
