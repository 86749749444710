import React from 'react';

function ZoomIcon({ className }) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="10" fill="#D3D2D2" />
      <g clip-path="url(#clip0_88_1230)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.99982 7.75C3.99982 7.35218 4.15785 6.97064 4.43916 6.68934C4.72046 6.40804 5.10199 6.25 5.49982 6.25H11.1248C11.4886 6.24996 11.8401 6.38214 12.1137 6.62195C12.3873 6.86175 12.5644 7.19282 12.6121 7.5535L14.9446 6.517C15.0587 6.46613 15.1838 6.4446 15.3084 6.45436C15.433 6.46413 15.5532 6.50488 15.6581 6.57292C15.7629 6.64096 15.8491 6.73412 15.9088 6.84394C15.9685 6.95376 15.9998 7.07676 15.9998 7.20175V12.7983C15.9997 12.9231 15.9684 13.046 15.9088 13.1558C15.8491 13.2655 15.763 13.3586 15.6583 13.4266C15.5535 13.4946 15.4335 13.5354 15.309 13.5453C15.1844 13.5551 15.0595 13.5337 14.9453 13.483L12.6121 12.4465C12.5644 12.8072 12.3873 13.1383 12.1137 13.3781C11.8401 13.6179 11.4886 13.75 11.1248 13.75H5.49982C5.10199 13.75 4.72046 13.592 4.43916 13.3107C4.15785 13.0294 3.99982 12.6478 3.99982 12.25V7.75Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_88_1230">
          <rect width="12" height="12" fill="white" transform="translate(3.99982 4)" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default ZoomIcon;