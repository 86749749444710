export const pageRoutes = {
  studentDashboard: "student-dashboard",
  dashboard: "dashboard",
  webinars: "webinars",
  jobs: "jobs",
  deliverables: "deliverables",
  course: "course",
  lesson: "lesson",
  aiReviewer: "aiReviewer",
  qa: "qa",
  settings: "settings",
  unauthorized: "unauthorized",
  login: "login",
};
export const pageLinks = {
  login: "/login",
};
export const roles = {
  studentRole: "student",
  instructorRole: "instructor",
};
