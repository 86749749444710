import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { pageRoutes } from "../../constants";
import { useGetUserProfileData } from "../../hooks/useGetUserProfileData";
import ProtectedLayout from "../layouts/ProtectedLayout";
import MobileNavBar from "./MobileNavBar/MobileNavBar";
import NavSideBar from "./NavSidebar/NavSideBar";
import UserProfileCard from "./UserProfile/UserProfileCard";

const DashboardLayout = () => {
  const location = useLocation();
  const { userProfileData, userSystemInfo } = useGetUserProfileData();

  return (
    <ProtectedLayout>
      <div className="tablet:bg-[#f8f8f8] bg-white gap-x-7 flex tablet:flex-nowrap flex-wrap">
        <NavSideBar />
        <MobileNavBar
          userProfileData={userProfileData}
          userSystemInfo={userSystemInfo}
        />
        <div className="relative z-[99] tablet:border border-gray-100 tablet:px-[1rem] px-[5%] py-[1.5rem] tablet:shadow-md tablet:mt-[2rem] mt-[6rem] bg-white tablet:rounded-xl tablet:h-fit h-full min-h-[90vh] flex-1">
          <Outlet />
        </div>
        {!location.pathname.includes(pageRoutes.course) && (
          <UserProfileCard
            userProfileData={userProfileData}
            userSystemInfo={userSystemInfo}
          />
        )}
      </div>
    </ProtectedLayout>
  );
};

export default DashboardLayout;
