import React, { useState } from 'react';
import axios from 'axios';
import { UserRequestSupportEndPoint } from '../../../../Services/endPoints';

function RequestSupport() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    issue: '',
  });

  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({}); // State to store form errors

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [id]: '' })); // Clear specific field error
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${UserRequestSupportEndPoint}`, {
        name: formData.name,
        email: formData.email,
        issue: formData.issue,
      });

      console.log('response', response);

      setMessage('Support request submitted successfully!');
      setErrors({}); // Clear errors on success
    } catch (error) {
      console.error('Error submitting support request:', error);

      if (error.response && error.response.data) {
        // API returned validation errors
        setErrors(error.response.data);
        setMessage('Failed to submit support request.');
      } else {
        // Generic error message
        setMessage('An unexpected error occurred.');
      }
    }
  };

  const formIsValid = formData.name && formData.email && formData.issue;

  return (
    <div className="shadow-lg p-5">
      <h2>Request Support</h2>
      <form className="my-5" onSubmit={handleSubmit}>
        <div className="w-[50%] my-5">
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className={`bg-gray-50 border ${errors.name ? 'border-red-500' : 'border-gray-300'
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
            placeholder="Example"
            required
          />
          {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
        </div>
        <div className="w-[50%] my-5">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Email
          </label>
          <input
            type="text"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className={`bg-gray-50 border ${errors.email ? 'border-red-500' : 'border-gray-300'
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
            placeholder="example@mail.com"
            required
          />
          {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.join(', ')}</p>}
        </div>
        <div className="w-[50%] my-5">
          <label
            htmlFor="issue"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Issue
          </label>
          <textarea
            rows={5}
            id="issue"
            value={formData.issue}
            onChange={handleChange}
            className={`bg-gray-50 border ${errors.issue ? 'border-red-500' : 'border-gray-300'
              } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
            placeholder="Describe your issue"
            required
          />
          {errors.issue && <p className="text-red-500 text-xs mt-1">{errors.issue}</p>}
        </div>
        <button type="submit" disabled={!formIsValid} className="btn btn-primary">
          SUBMIT
        </button>
      </form>
      {message && <strong><p className={`mt-5 text-sm ${message.includes('Failed') ? 'text-red-500' : 'text-green-500'}`}>{message}</p></strong>}
    </div>
  );
}

export default RequestSupport;
