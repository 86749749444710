import React from "react";

const BuildingIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 9.375H10V10.625H11.25M11.25 6.875H10V8.125H11.25M12.5 11.875H7.5V10.625H8.75V9.375H7.5V8.125H8.75V6.875H7.5V5.625H12.5M6.25 4.375H5V3.125H6.25M6.25 6.875H5V5.625H6.25M6.25 9.375H5V8.125H6.25M6.25 11.875H5V10.625H6.25M3.75 4.375H2.5V3.125H3.75M3.75 6.875H2.5V5.625H3.75M3.75 9.375H2.5V8.125H3.75M3.75 11.875H2.5V10.625H3.75M7.5 4.375V1.875H1.25V13.125H13.75V4.375H7.5Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default BuildingIcon;
