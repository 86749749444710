import React from 'react'

export default function UnWatched({ className }) {
    return (
        <svg
            width={30}
            height={29}
            viewBox="0 0 30 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle
                cx={15.05}
                cy={14.2626}
                r={12.825}
                stroke="#273C97"
                strokeWidth={2.85}
            />
        </svg>
    )
}
