import React from 'react'

export default function DashboardPerson({ className }) {
    return (
        <svg
            width={18}
            height={19}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g id="person_24px">
                <path
                    id="icon/social/person_24px"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.99963 3.10352C7.34213 3.10352 5.99963 4.49231 5.99963 6.20696C5.99963 7.92162 7.34213 9.31041 8.99963 9.31041C10.6571 9.31041 11.9996 7.92162 11.9996 6.20696C11.9996 4.49231 10.6571 3.10352 8.99963 3.10352ZM10.5003 6.20693C10.5003 5.35348 9.82535 4.65521 9.00034 4.65521C8.17534 4.65521 7.50035 5.35348 7.50035 6.20693C7.50035 7.06038 8.17534 7.75866 9.00034 7.75866C9.82535 7.75866 10.5003 7.06038 10.5003 6.20693ZM13.5005 13.9656C13.3505 13.4148 11.0255 12.4139 9.00047 12.4139C6.98297 12.4139 4.67297 13.407 4.50047 13.9656H13.5005ZM2.99976 13.9656C2.99976 11.9018 6.99726 10.8621 8.99975 10.8621C11.0023 10.8621 14.9998 11.9018 14.9998 13.9656V15.5173H2.99976V13.9656Z"
                    fill="#273C97"
                />
            </g>
        </svg>
    )
}
