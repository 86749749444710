import React from 'react';
import styles from './SearchStudent.module.css';
import Image from '../../../assets/OBJECTS.svg';
import useFetch from '../../../hooks/useFetch';
import { NextWebinars } from '../../../Services/endPoints';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CalenderIcon from '../../../Components/svgs/CalenderIcon';
import Person from '../../../Components/svgs/Person';
import TimeIcon from '../../../Components/svgs/TimeIcon';
import ZoomIcon from '../../../Components/svgs/ZoomIcon';

function SearchStudent() {
  const {
    data: nextWebinarData,
    loading: userFreelancePlatformsExternalLoading,
    error: userFreelancePlatformsExternalError,
  } = useFetch(NextWebinars);
  console.log('userFreelancePlatformsExternalLoading', nextWebinarData);

  // Display loading message or spinner
  if (userFreelancePlatformsExternalLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (userFreelancePlatformsExternalError) {
    return <div className={styles.error}>Error: {userFreelancePlatformsExternalError.message}</div>;
  }

  return (
    <div className="flex bg-primary border rounded-2xl py-5 text-white pl-[3rem] justify-between pr-[15rem] ">
      <div>
        <h4 className='mb-2 text-xl'>Your Next Webinar</h4>
        <h2 className='text-2xl mb-5 font-semibold'>{nextWebinarData?.name}</h2>
        <div className='flex align-center justify-between mb-4 text-xl'>
          <div className='flex align-center mr-5'>
            <CalenderIcon className="text-white mr-2" />
            <h1> {nextWebinarData?.date} </h1>
          </div>
          <div className='flex align-center'>
            <Person className="text-white mr-2" />
            <h1> {nextWebinarData?.instructor_name} </h1>
          </div>
        </div>
        <div className='flex align-center justify-between text-xl'>
          <div className='flex align-center mr-5'>
            <TimeIcon className="text-white mr-2" />
            <h1> {nextWebinarData?.time} </h1>
          </div>
          <div className='flex align-center'>
            <ZoomIcon className="text-white mr-2" />
            <h1> {nextWebinarData?.date} </h1>
          </div>
        </div>

      </div>

      <div   >
        <img  src={Image} alt="Webinar Banner" />
      </div>
    </div>
  );
}

export default SearchStudent;
