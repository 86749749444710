import React from 'react';

function GroupPeople({ className }) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 5.40851C11.96 5.40184 11.9133 5.40184 11.8733 5.40851C10.9533 5.37518 10.22 4.62184 10.22 3.68851C10.22 2.73517 10.9866 1.96851 11.94 1.96851C12.8933 1.96851 13.66 2.74184 13.66 3.68851C13.6533 4.62184 12.92 5.37518 12 5.40851Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M11.3132 10.2619C12.2265 10.4153 13.2332 10.2553 13.9399 9.78195C14.8799 9.15528 14.8799 8.12861 13.9399 7.50195C13.2265 7.02861 12.2065 6.86861 11.2932 7.02861" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M3.98007 5.40851C4.02007 5.40184 4.06674 5.40184 4.10674 5.40851C5.02674 5.37518 5.76007 4.62184 5.76007 3.68851C5.76007 2.73517 4.9934 1.96851 4.04007 1.96851C3.08674 1.96851 2.32007 2.74184 2.32007 3.68851C2.32674 4.62184 3.06007 5.37518 3.98007 5.40851Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M4.66663 10.2619C3.75329 10.4153 2.74663 10.2553 2.03996 9.78195C1.09996 9.15528 1.09996 8.12861 2.03996 7.50195C2.75329 7.02861 3.77329 6.86861 4.68663 7.02861" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M7.99997 10.3885C7.95997 10.3818 7.9133 10.3818 7.8733 10.3885C6.9533 10.3552 6.21997 9.60182 6.21997 8.66849C6.21997 7.71515 6.98664 6.94849 7.93997 6.94849C8.8933 6.94849 9.65997 7.72182 9.65997 8.66849C9.6533 9.60182 8.91997 10.3618 7.99997 10.3885Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
      <path d="M6.05998 12.4885C5.11998 13.1152 5.11998 14.1418 6.05998 14.7685C7.12665 15.4818 8.87331 15.4818 9.93998 14.7685C10.88 14.1418 10.88 13.1152 9.93998 12.4885C8.87998 11.7818 7.12665 11.7818 6.05998 12.4885Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" />
    </svg>
  );
}

export default GroupPeople;