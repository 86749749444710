import Plyr from "plyr-react";
import "plyr/dist/plyr.css";
import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LeftArrow from "../../../../Components/svgs/LeftArrow";
import RightArrow from "../../../../Components/svgs/RightArrow";
import { useCourseLayoutContext } from "../CourseLayoutProvider";
import style from "./LessonViewer.module.css";
import { pageRoutes } from "../../../../constants";
import axios from "axios";
import { CompleteLessonEndPoint } from "../../../../Services/endPoints";

function LessonViewer() {
  const playerRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { courseData, courseLoading, courseError } = useCourseLayoutContext();

  if (courseLoading) return <div>Loading...</div>;
  if (courseError) return <div>Error loading course data.</div>;

  const courseLectures = courseData?.lessons || [];
  const currentLecture = courseLectures.find(
    (lecture) => lecture.id === parseInt(id)
  );
  const currentLectureIndex = courseLectures.findIndex(
    (lecture) => lecture.id === parseInt(id)
  );

  const handleNextLecture = async () => {
    if (currentLectureIndex < courseLectures.length - 1) {
      const nextLectureId = courseLectures[currentLectureIndex + 1].id;
      navigate(`/${pageRoutes.course}/${pageRoutes.lesson}/${nextLectureId}`);
      await axios.post(CompleteLessonEndPoint, {
        lesson: nextLectureId,
      });
    } else {
      await axios.post(CompleteLessonEndPoint, {
        lesson: courseLectures[currentLectureIndex].id,
      });
    }
  };

  const handlePrevLecture = () => {
    if (currentLectureIndex > 0) {
      const prevLectureId = courseLectures[currentLectureIndex - 1].id;
      navigate(`/${pageRoutes.course}/${pageRoutes.lesson}/${prevLectureId}`);
    }
  };

  if (!currentLecture) return <div>No lecture found.</div>;

  return (
    <div className={style.courseContainer}>
      <Link to="/" className={`w-fit hover:no-underline ${style.exitButton}`}>
        Exit Lesson
      </Link>

      <div className={style.videoContainer}>
        <Plyr
          ref={playerRef}
          source={{
            type: "video",
            sources: [
              {
                src: currentLecture?.video || "",
                provider: currentLecture?.video?.includes("youtube.com")
                  ? "youtube"
                  : "html5",
              },
            ],
          }}
        />

        <div className={style.navigationDiv}>
          <button
            className={`py-1 pe-1 bg-[#FFFFFF] ${
              currentLectureIndex === 0 ? style.disabled : ""
            }`}
            onClick={handlePrevLecture}
            disabled={currentLectureIndex === 0}
          >
            <LeftArrow />
          </button>
          <button
            className={`py-1 ps-1 bg-[#FFFFFF] ${
              currentLectureIndex === courseLectures.length - 1
                ? style.disabled
                : ""
            }`}
            onClick={handleNextLecture}
            // disabled={currentLectureIndex === courseLectures.length - 1}
          >
            <RightArrow />
          </button>
        </div>
      </div>

      <div className={style.overviewSection}>
        <h2>{currentLecture.title}</h2>
        <p>{courseData.overview}</p>
      </div>
    </div>
  );
}

export default LessonViewer;
