import React from 'react'

export default function WatchedIcon({ className }) {
    return (
        <svg
            width={30}
            height={29}
            viewBox="0 0 30 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <circle cx={15.05} cy={14.7124} r={14.25} fill="#273C97" />
            <mask
                id="mask0_132_3561"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={30}
                height={29}
            >
                <rect
                    x={0.800049}
                    y={0.462402}
                    width={28.5}
                    height={28.5}
                    fill="#D9D9D9"
                />
            </mask>
            <g mask="url(#mask0_132_3561)">
                <path
                    d="M12.6765 16.6128L19.6827 9.60659C19.9004 9.38888 20.1775 9.28003 20.514 9.28003C20.8504 9.28003 21.1275 9.38888 21.3452 9.60659C21.5629 9.8243 21.6718 10.1014 21.6718 10.4378C21.6718 10.7743 21.5629 11.0514 21.3452 11.2691L13.5077 19.1066C13.2702 19.3441 12.9931 19.4628 12.6765 19.4628C12.3598 19.4628 12.0827 19.3441 11.8452 19.1066L8.75771 16.0191C8.54001 15.8014 8.43115 15.5243 8.43115 15.1878C8.43115 14.8514 8.54001 14.5743 8.75771 14.3566C8.97542 14.1389 9.25251 14.03 9.58896 14.03C9.92542 14.03 10.2025 14.1389 10.4202 14.3566L12.6765 16.6128Z"
                    fill="white"
                />
            </g>
        </svg>
    )
}
