import { API_URL, getToken } from "../../../../Helpers/constants";

import { useState } from "react";
import fiverr from "../../../../assets/fiver.png";
import guru from "../../../../assets/guru.png";
import linkedin from "../../../../assets/icons8-linkedin-96.svg";
import khamsat from "../../../../assets/khmsat.png";
import mostaql from "../../../../assets/Mostaql-Logo.png";
import nabbesh from "../../../../assets/nbeesh.jpg";
import peoplePerHour from "../../../../assets/peoplePerHr.png";
import tasmeemme from "../../../../assets/tsmem.png";
import upWork from "../../../../assets/upwork.png";
import useFetch from "../../../../hooks/useFetch";
import {
  freelancePlatformsEndpoint,
  freelanceProfileLinksEndpoint,
} from "../../../../Services/endPoints";
const platformLogos = {
  UpWork: upWork,
  Fiverr: fiverr,
  Khamsat: khamsat,
  Nabbesh: nabbesh,
  Freelancer: "https://cdn6.f-cdn.com/build/icons/fl-logo.svg",
  Ureed: "https://ureed.com/favicon.ico",
  Tasmeemme: tasmeemme,
  Bawaba:
    "https://www.albawaba.com/sites/default/themes/zing/images/favicon.ico",
  FlexJobs: "https://www.flexjobs.com/favicon.ico",
  Mostaql: mostaql,
  Linkedin: linkedin,
  Guru: guru,
  PeoplePerHour: peoplePerHour,
  Other: "https://via.placeholder.com/50",
};

export const useFreelanceAccounts = () => {
  const [newPlatform, setNewPlatform] = useState("");
  const [showInputs, setShowInputs] = useState(false);
  const [platformId, setPlatformId] = useState("");
  const [urlError, setUrlError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const dynamicToken = getToken();
  const {
    data: freelancePlatforms,
    loading: freelancePlatformsLoading,
    error: freelancePlatformsError,
  } = useFetch(freelancePlatformsEndpoint);
  const {
    data: freelanceProfileLinks,
    loading,
    error,
  } = useFetch(freelanceProfileLinksEndpoint);

  const postPlatformLink = () => {
    setUrlError("");
    setSubmitError("");

    if (!newPlatform.trim()) {
      setUrlError("Platform URL is required.");
      return;
    }
    if (!newPlatform.startsWith("https://")) {
      setUrlError('Platform URL must start with "https://".');
      return;
    }
    if (!platformId) {
      setSubmitError("You must select a platform.");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `jwt ${dynamicToken}`);

    const raw = JSON.stringify({ url: newPlatform, platform: platformId });

    fetch(`${API_URL}/freelance-profile-links/`, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            if (data.url && Array.isArray(data.url)) {
              setUrlError(data.url[0]);
            }
            throw new Error("Validation error occurred");
          });
        }
        return response.json();
      })
      .then(() => {
        setNewPlatform("");
        setPlatformId("");
        setShowInputs(false);
      })
      .catch((error) =>
        setSubmitError(error.message || "An error occurred while submitting.")
      );
  };
  return {
    newPlatform,
    setNewPlatform,
    showInputs,
    setShowInputs,
    postPlatformLink,
    platformId,
    setPlatformId,
    urlError,
    setUrlError,
    submitError,
    setSubmitError,
    freelancePlatforms,
    freelanceProfileLinks,
    platformLogos,
  };
};
