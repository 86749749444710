import React from 'react';

function TimeIcon({ className }) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_76_1194)">
        <path d="M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM10 4.375C10 4.20924 9.93415 4.05027 9.81694 3.93306C9.69973 3.81585 9.54076 3.75 9.375 3.75C9.20924 3.75 9.05027 3.81585 8.93306 3.93306C8.81585 4.05027 8.75 4.20924 8.75 4.375V11.25C8.75004 11.3602 8.77919 11.4684 8.83451 11.5636C8.88982 11.6589 8.96934 11.7379 9.065 11.7925L13.44 14.2925C13.5836 14.3701 13.7518 14.3885 13.9087 14.3437C14.0657 14.2989 14.1988 14.1944 14.2798 14.0527C14.3608 13.9111 14.3831 13.7433 14.342 13.5854C14.301 13.4274 14.1997 13.2918 14.06 13.2075L10 10.8875V4.375Z" fill="#D3D2D2" />
      </g>
      <defs>
        <clipPath id="clip0_76_1194">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
}

export default TimeIcon;