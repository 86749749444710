import axios from "axios";
import { UserChangeProfilePicture } from "./endPoints";

const putChangeProfileImage = async (image) => {
    const formData = new FormData();
    formData.append('profile_image', image);

    try {
        const response = await axios.put(UserChangeProfilePicture, formData);
        return response.data;
    } catch (error) {
        console.error("Error changing profile image:", error);
        throw error;
    }
};

export default putChangeProfileImage;