import { Link, useLocation, useNavigate } from "react-router-dom";
import BagIcon from "../../../Components/svgs/BagIcon";
import GraduatuedCap from "../../../Components/svgs/GraduatuedCap";
import GroupPeople from "../../../Components/svgs/GroupPeople";
import HomeIcon from "../../../Components/svgs/HomeIcon";
import LogoutIcon from "../../../Components/svgs/LogoutIcon";
import QAIcon from "../../../Components/svgs/QAIcon";
import RobotIcon from "../../../Components/svgs/RobotIcon";
import SettingsIcon from "../../../Components/svgs/SettingsIcon";
import Task from "../../../Components/svgs/Task";
import { pageRoutes } from "../../../constants";
const tabs = [
  {
    pageRoute: pageRoutes.dashboard,
    label: "Dashboard",
    icon: <HomeIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.webinars,
    label: "Webinars",
    icon: <GroupPeople className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.jobs,
    label: "Gigs",
    icon: <BagIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.deliverables,
    label: "Deliverables",
    icon: <Task className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.course,
    label: "Course",
    icon: <GraduatuedCap className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.aiReviewer,
    label: "AI Reviewer",
    icon: <RobotIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
  {
    pageRoute: pageRoutes.qa,
    label: "Q&A",
    icon: <QAIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />,
  },
];

const NavigationTabs = ({ isSideBarOpen = true, closeDrawer = () => {} }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("role");
    navigate("/login");
  };
  return (
    <>
      {tabs.map((tab) => (
        <li
          key={tab.pageRoute}
          className="w-full list-none"
          onClick={closeDrawer}
        >
          <Link
            className={`${
              location.pathname.includes(`/${tab.pageRoute}`)
                ? "bg-primary text-white hover:bg-primary"
                : "hover:bg-blue-600 hover:bg-opacity-25"
            } flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-out  overflow-hidden`}
            to={`/${tab.pageRoute}`}
          >
            {tab.icon}
            <span
              className={`duration-300 ease-in-out ${
                isSideBarOpen ? "opacity-100" : "opacity-0"
              }`}
            >
              {tab.label}
            </span>
          </Link>
        </li>
      ))}
      <li className="w-full list-none t mt-auto" onClick={closeDrawer}>
        <Link
          className={`${
            location.pathname === pageRoutes.settings
              ? "bg-primary text-white"
              : "hover:bg-blue-600 hover:bg-opacity-25"
          } flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-ou overflow-hidden`}
          to={pageRoutes.settings}
        >
          <SettingsIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />
          <span
            className={`duration-300 ease-in-out ${
              isSideBarOpen ? "opacity-100" : "opacity-0"
            }`}
          >
            Settings
          </span>
        </Link>
      </li>
      <button
        className={`flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-out overflow-hidden text-red-250 hover:bg-red-100 `}
        onClick={logout}
      >
        <LogoutIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0" />
        <span
          className={`text-red-250 duration-300 ease-in-out ${
            isSideBarOpen ? "opacity-100" : "opacity-0"
          }`}
        >
          Logout
        </span>
      </button>
    </>
  );
};

export default NavigationTabs;
