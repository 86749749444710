import React, { useEffect, useState } from 'react';
import ArrowWhite from '../../../Components/svgs/ArrowWhite';
import { UserDeliverablesStudents } from '../../../Services/endPoints';
import useFetch from '../../../hooks/useFetch';
import DeliverableDetails from './DeliverableDetails';
import styles from './Tasks.module.css';

function Deliverables() {
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);

  const {
    data: UserDeliverablesStudentsData,
    loading: UserDeliverablesStudentsLoading,
    error: UserDeliverablesStudentsError,
  } = useFetch(UserDeliverablesStudents);

  useEffect(() => {
    // Set the first deliverable as the selected one when data is available
    if (UserDeliverablesStudentsData && UserDeliverablesStudentsData.length > 0) {
      setSelectedDeliverable(UserDeliverablesStudentsData[0]);
    }
  }, [UserDeliverablesStudentsData]);

  const getArrowColor = (completed) => {
    if (completed === true) return '#273C97';
    if (completed === false) return '#F0F0F0';
    return '#ADD8E6';
  };

  const handleCardClick = (deliverable) => {
    setSelectedDeliverable(deliverable);
  };

  if (UserDeliverablesStudentsLoading) return <p>Loading...</p>;
  if (UserDeliverablesStudentsError) return <p>Error loading data</p>;

  return (
    <div className={styles.dashboardOverview}>
      <h1 className='text-center text-2xl my-6 text-[#202020] font-semibold'>
        Delivers Timeline{' '}
      </h1>
      {/* Timeline Arrows */}
      <div className='flex gap-4 items-center justify-center'>
        {UserDeliverablesStudentsData?.map((deliverable, index) => (
          <ArrowWhite
            key={index}
            color={getArrowColor(deliverable.completed)}
            className='w-40 h-40'
            text={deliverable.week}
          />
        ))}
      </div>

      {/* Deliverable Cards */}
      <div className='ml-16'>
        <div className='flex mx-4 my-5 gap-4 flex-wrap justify-start'>
          {UserDeliverablesStudentsData?.map((deliverable, index) => (
            <div
              key={index}
              className={`border rounded-3xl p-5 w-[22%] flex items-center justify-center gap-3 cursor-pointer`}
              style={{ backgroundColor: '#273C97' }}
              onClick={() => handleCardClick(deliverable)}
            >
              {/* <AddIcon className='w-7 h-7' /> */}
              <h1 className='text-base my-6 text-[#F0F0F0] font-semibold'>
                {deliverable.week} Deliverables
              </h1>
            </div>
          ))}
        </div>
      </div>

      {selectedDeliverable && (
        <DeliverableDetails deliverableId={selectedDeliverable.id} />
      )}
    </div>
  );
}

export default Deliverables;
