function PhoneIconSVG({ className }) {
  return (
    <svg
      className={className}
      width="9"
      height="14"
      viewBox="0 0 9 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25 11.3333H5.75M4.20826 13H4.79174C6.23258 13 6.953 13 7.45803 12.6331C7.62114 12.5146 7.76457 12.3711 7.88307 12.208C8.25 11.703 8.25 10.9826 8.25 9.54175V4.45826C8.25 3.01742 8.25 2.297 7.88307 1.79197C7.76457 1.62886 7.62114 1.48543 7.45803 1.36693C6.953 1 6.23258 1 4.79174 1H4.20826C2.76742 1 2.047 1 1.54197 1.36693C1.37886 1.48543 1.23543 1.62886 1.11693 1.79197C0.75 2.297 0.75 3.01742 0.75 4.45826V9.54175C0.75 10.9826 0.75 11.703 1.11693 12.208C1.23543 12.3711 1.37886 12.5146 1.54197 12.6331C2.047 13 2.76742 13 4.20826 13Z"
        stroke="currentcolor"
        stroke-width="0.960627"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default PhoneIconSVG;
