import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common["Authorization"] =
  `jwt ${localStorage.getItem("access_token")}` || "";
axios.defaults.headers.common["accept"] = "application/json";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// <React.StrictMode>
// <App />
// </React.StrictMode>
