import React from 'react';

function BagIcon({ className }) {
  return (

    <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.54167 1.91675H9.45833C9.8035 1.91675 10.0833 2.19657 10.0833 2.54175V4.00008H5.91667V2.54175C5.91667 2.19657 6.19649 1.91675 6.54167 1.91675ZM4.66667 2.54175V4.00008H3.20833C1.71256 4.00008 0.5 5.21264 0.5 6.70841V7.95841C0.5 8.76383 1.15292 9.41675 1.95833 9.41675H6.33333V9.00008C6.33333 8.53983 6.70642 8.16675 7.16667 8.16675H8.83333C9.29358 8.16675 9.66667 8.53983 9.66667 9.00008V9.41675H14.0417C14.8471 9.41675 15.5 8.76383 15.5 7.95841V6.70841C15.5 5.21264 14.2874 4.00008 12.7917 4.00008H11.3333V2.54175C11.3333 1.50621 10.4938 0.666748 9.45833 0.666748H6.54167C5.50613 0.666748 4.66667 1.50621 4.66667 2.54175ZM15.5 10.241C15.0791 10.5105 14.5786 10.6667 14.0417 10.6667H9.66667C9.66667 11.127 9.29358 11.5001 8.83333 11.5001H7.16667C6.70642 11.5001 6.33333 11.127 6.33333 10.6667H1.95833C1.4214 10.6667 0.920958 10.5105 0.5 10.241V12.9584C0.5 14.4542 1.71256 15.6667 3.20833 15.6667H12.7917C14.2874 15.6667 15.5 14.4542 15.5 12.9584V10.241Z" fill="currentColor" />
    </svg>

  );
}

export default BagIcon;