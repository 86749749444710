import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './QA.module.css';
import useFetch from '../../../hooks/useFetch';
import {
    categoryEndPoint,
    questionEndPoint,
} from '../../../Services/endPoints';
import { useSearchParams } from 'react-router-dom';
import postQuestion from '../../../Services/apiPostQuestion';
import { CircularProgress } from '@mui/material';

export default function QA() {
    const [expanded, setExpanded] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedCategory, setSelectedCategory] = useState('');

    const filter = searchParams.get('filter') === 'all' ? 'all' : 'my';

    // Update endpoint based on filter and selected category
    const filteredQuestionEndPoint =
        filter === 'all'
            ? `${questionEndPoint}?all=true${selectedCategory ? `&category=${selectedCategory}` : ''}`
            : `${questionEndPoint}${selectedCategory ? `?category=${selectedCategory}` : ''}`;
    // Fetch categories and questions
    const {
        data: categoryData,
        loading: categoryLoading,
        error: categoryError,
    } = useFetch(categoryEndPoint);

    const {
        data: questionData,
        loading: questionLoading,
        error: questionError,
    } = useFetch(filteredQuestionEndPoint);

    useEffect(() => {
        if (categoryData?.length > 0) {
            formik.setFieldValue('category', categoryData[0].id);
        }
    }, [categoryData]);

    const formik = useFormik({
        initialValues: {
            questionText: '',
            category: '',
        },
        validationSchema: Yup.object({
            questionText: Yup.string()
                .required('Question text is required')
                .min(5, 'Question must be at least 5 characters'),
            category: Yup.string().required('Category is required'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await postQuestion(
                    values.questionText,
                    values.category
                );
                // console.log("Question posted successfully:", response);
                resetForm();
                setErrorMessage('');
                window.location.reload(); // Reload the page to fetch new questions 
            } catch (error) {
                console.error('Failed to post question:', error);
                const message =
                    error.response?.data || 'An error occurred. Please try again.';
                setErrorMessage(
                    typeof message === 'string' ? message : JSON.stringify(message)
                );
            }
        },
    });

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Category filter change handler
    const handleCategoryFilterChange = (event) => {
        const selectedCategory = event.target.value;
        setSelectedCategory(selectedCategory);
        // setSearchParams({ filter, category: selectedCategory });

        const params = {};
        if (filter !== 'my') params.filter = filter;
        if (selectedCategory) params.category = selectedCategory;
        setSearchParams(params);
    };

    // Filter change handler for all/my questions
    const handleFilterChange = (event) => {
        const selectedFilter = event.target.value;
        // setSearchParams({ filter: selectedFilter, category: selectedCategory });

        const params = {};
        if (selectedFilter !== 'my') params.filter = selectedFilter;
        if (selectedCategory) params.category = selectedCategory;
        setSearchParams(params);
    };

    if (categoryLoading) {
        return (
            <div className='flex justify-center items-center'>
                <CircularProgress />
            </div>
        );
    }

    if (categoryError) {
        return (
            <div className='border border-red-700 p-5 text-red-700'>
                Error: {categoryError.message}
            </div>
        );
    }

    const questions = questionData?.results || [];

    if (questionLoading) {
        return (
            <div className='flex justify-center items-center'>
                <CircularProgress />
            </div>
        );
    }

    if (questionError) {
        return (
            <div className='border border-red-700 p-5 text-red-700'>
                Error: {questionError.message}
            </div>
        );
    }

    return (
        <div className={styles.qaContainer}>
            {/* Submit your question section */}
            <form
                onSubmit={formik.handleSubmit}
                className={styles.submitQuestionSection}
            >
                <div className='flex flex-col'>
                    <div>
                        <input
                            type='text'
                            name='questionText'
                            className={` w-80 border border-solid ${formik.touched.questionText && formik.errors.questionText
                                ? 'border-[red]'
                                : 'border-[#9D9D9D]'
                                } ${styles.inputQA}`}
                            placeholder='Write your Question'
                            value={formik.values.questionText}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        <select
                            name='category'
                            className={`w-60 ${styles.selectQA}`}
                            value={formik.values.category}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            <option value='' disabled>
                                Select Category
                            </option>
                            {categoryData.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        {formik.touched.questionText && formik.errors.questionText ? (
                            <div className='text-[red] text-[0.8rem] font-light ml-3'>
                                {formik.errors.questionText}
                            </div>
                        ) : null}
                        {formik.touched.category && formik.errors.category ? (
                            <div className='text-[red] text-[0.8rem] font-light ml-3'>
                                {formik.errors.category}
                            </div>
                        ) : null}
                    </div>
                </div>
                <button type='submit' className={styles.submitQA}>
                    Submit
                </button>
                {errorMessage && (
                    <div className='border border-red-900 p-5 text-red-700'>
                        {errorMessage}
                    </div>
                )}
            </form>

            <div className='flex place-content-end mb-4 gap-4'>
                <select
                    name='categoryFilter'
                    className='rounded-3xl p-3 px-5 border border-[#EAEEF4] bg-[#FFFFFF]'
                    onChange={handleCategoryFilterChange}
                    value={selectedCategory}
                >
                    <option value=''>
                        All
                    </option>
                    {categoryData.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>
                <select
                    name='allMyFilter'
                    className='rounded-3xl p-3 px-5 border border-[#EAEEF4] bg-[#FFFFFF]'
                    onChange={handleFilterChange}
                    value={filter}
                >
                    <option value='my'>My Questions</option>
                    <option value='all'>All Questions</option>
                </select>
            </div>

            {/* Accordion section */}
            <div className={styles.accordionQAContainer}>
                {questions.map((question) => (
                    <Accordion
                        key={question.id}
                        expanded={question.answer && expanded === `panel${question.id}`}
                        onChange={handleChange(`panel${question.id}`)}
                        className={`${styles.accordionQA} ${!question.answer ? styles.disabledAccordion : ''
                            }`}
                        disableGutters
                    >
                        <AccordionSummary
                            expandIcon={
                                question.answer ? (
                                    <ExpandMoreIcon />
                                ) : (
                                    <div className='invisible'>
                                        <ExpandMoreIcon />
                                    </div>
                                )
                            }
                            aria-controls={`panel${question.id}-content`}
                            id={`panel${question.id}-header`}
                            className={!question.answer ? styles.disabledSummary : ''}
                        >
                            <div className={styles.accordionHeader}>
                                <h2>{question.question_text}</h2>
                                <div className='flex items-center gap-3'>
                                    {!question.answer && (
                                        <h4 className='border-red-600 border rounded text-red-600 p-1 px-2 h-8 text-sm'>
                                            Not Answered
                                        </h4>
                                    )}
                                    <h3 className='border-[#273C97] me-2 border-2 rounded text-[#273C97] p-2'>
                                        {question.category.name}
                                    </h3>
                                </div>
                            </div>
                        </AccordionSummary>
                        {question.answer && (
                            <AccordionDetails>
                                <p className={styles.accordionBody}>{question.answer.answer}</p>
                            </AccordionDetails>
                        )}
                    </Accordion>
                ))}
            </div>
        </div>
    );
}