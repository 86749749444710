import { format, parse } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UnwatchedIcon from "../../../../Components/svgs/UnWatched";
import VideoIcon from "../../../../Components/svgs/VideoIcon";
import WatchedIcon from "../../../../Components/svgs/WatchedIcon";
import { useCourseLayoutContext } from "../CourseLayoutProvider";
import styles from "./CourseVideoLessons.module.css";

function CourseVideoLessons() {
  const { id } = useParams();
  const [activeLesson, setActiveLesson] = useState(0);

  const { courseData, courseLoading, courseError } = useCourseLayoutContext();

  // Set the active lesson based on the loaded data
  useEffect(() => {
    if (courseData?.lessons?.length > 0) {
      const lessonIndex = courseData.lessons.findIndex(
        (lesson) => lesson.id === parseInt(id)
      );
      setActiveLesson(lessonIndex);
    }
  }, [courseData, id]);

  const formatDuration = (duration) => {
    const parsedTime = parse(duration, "HH:mm:ss", new Date());
    return format(parsedTime, "HH:mm");
  };

  // Handle loading and error states
  if (courseLoading) return <div>Loading...</div>;
  if (courseError) return <div>Error loading course data.</div>;

  return (
    <div className={styles.courseVideoLessons}>
      <h2 className="font-bold text-xl text-[#1F2029] text-left my-6">
        {courseData?.name || "Course"}
      </h2>
      <ul className={styles.lessonList}>
        {courseData?.lessons?.map((lesson, index) => (
          <li
            key={lesson.id}
            className={`${styles.lessonItem} ${
              index === activeLesson ? styles.activeLesson : ""
            }`}
            // onClick={() => handleLessonClick(index)}
          >
            <Link
              to={`/course/lesson/${lesson.id}`}
              // onClick={() => setActiveLesson(index)}
              className="flex items-center"
            >
              <span className={styles.icon}>
                {lesson.progress && lesson.progress.completed ? (
                  <WatchedIcon className="w-6" />
                ) : (
                  <UnwatchedIcon className="w-6" />
                )}
              </span>
              <VideoIcon className="mx-2 mb-4" />
              <div className={styles.lessonInfo}>
                <p className={styles.lessonTitle}>{lesson.title}</p>
                <p className={styles.lessonDuration}>
                  (
                  {lesson.duration
                    ? formatDuration(lesson.duration)
                    : "Unknown"}
                  )
                </p>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CourseVideoLessons;
