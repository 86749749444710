import React from 'react'

export default function DashboardBag({ className }) {
    return (
        <svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M8.54167 2.91675H11.4583C11.8035 2.91675 12.0833 3.19657 12.0833 3.54175V5.00008H7.91667V3.54175C7.91667 3.19657 8.19649 2.91675 8.54167 2.91675ZM6.66667 3.54175V5.00008H5.20833C3.71256 5.00008 2.5 6.21264 2.5 7.70841V8.95841C2.5 9.76383 3.15292 10.4167 3.95833 10.4167H8.33333V10.0001C8.33333 9.53983 8.70642 9.16675 9.16667 9.16675H10.8333C11.2936 9.16675 11.6667 9.53983 11.6667 10.0001V10.4167H16.0417C16.8471 10.4167 17.5 9.76383 17.5 8.95841V7.70841C17.5 6.21264 16.2874 5.00008 14.7917 5.00008H13.3333V3.54175C13.3333 2.50621 12.4938 1.66675 11.4583 1.66675H8.54167C7.50613 1.66675 6.66667 2.50621 6.66667 3.54175ZM17.5 11.241C17.0791 11.5105 16.5786 11.6667 16.0417 11.6667H11.6667C11.6667 12.127 11.2936 12.5001 10.8333 12.5001H9.16667C8.70642 12.5001 8.33333 12.127 8.33333 11.6667H3.95833C3.4214 11.6667 2.92096 11.5105 2.5 11.241V13.9584C2.5 15.4542 3.71256 16.6667 5.20833 16.6667H14.7917C16.2874 16.6667 17.5 15.4542 17.5 13.9584V11.241Z"
                fill="#273C97"
            />
        </svg>
    )
}
