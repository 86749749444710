import Course from "./Course";
import { CourseLayoutProvider } from "./CourseLayoutProvider";

const CourseLayout = ({ children }) => {
  return (
    <CourseLayoutProvider>
      <Course />
    </CourseLayoutProvider>
  );
};

export default CourseLayout;
