import * as React from "react";
const EditIcon = (props) => (
  <svg width="34" {...props} height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_204_1786)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83318 9.51946C6.60677 8.74588 7.65598 8.31128 8.75 8.31128H12.875C13.6344 8.31128 14.25 8.92689 14.25 9.68628C14.25 10.4457 13.6344 11.0613 12.875 11.0613H8.75C8.38533 11.0613 8.03559 11.2061 7.77773 11.464C7.51987 11.7219 7.375 12.0716 7.375 12.4363V24.8113C7.375 25.176 7.51987 25.5257 7.77773 25.7836C8.03559 26.0414 8.38533 26.1863 8.75 26.1863H21.125C21.4897 26.1863 21.8394 26.0414 22.0973 25.7836C22.3551 25.5257 22.5 25.176 22.5 24.8113V20.6863C22.5 19.9269 23.1156 19.3113 23.875 19.3113C24.6344 19.3113 25.25 19.9269 25.25 20.6863V24.8113C25.25 25.9053 24.8154 26.9545 24.0418 27.7281C23.2682 28.5017 22.219 28.9363 21.125 28.9363H8.75C7.65598 28.9363 6.60677 28.5017 5.83318 27.7281C5.0596 26.9545 4.625 25.9053 4.625 24.8113V12.4363C4.625 11.3423 5.0596 10.2931 5.83318 9.51946Z" fill="#273C97" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.59 3.90157C24.3949 3.0967 25.4865 2.64453 26.6248 2.64453C27.763 2.64453 28.8547 3.0967 29.6595 3.90157C30.4644 4.70645 30.9166 5.79809 30.9166 6.93635C30.9166 8.07461 30.4644 9.16625 29.6595 9.97112L17.972 21.6586C17.7142 21.9165 17.3644 22.0613 16.9998 22.0613H12.8748C12.1154 22.0613 11.4998 21.4457 11.4998 20.6863V16.5613C11.4998 16.1967 11.6446 15.8469 11.9025 15.5891L23.59 3.90157ZM26.6248 5.39453C26.2158 5.39453 25.8237 5.55697 25.5345 5.84612L14.2498 17.1309V19.3113H16.4302L27.715 8.02657C28.0041 7.73743 28.1666 7.34526 28.1666 6.93635C28.1666 6.52743 28.0041 6.13527 27.715 5.84612C27.4258 5.55697 27.0337 5.39453 26.6248 5.39453Z" fill="#273C97" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5277 5.96401C22.0647 5.42704 22.9353 5.42704 23.4723 5.96401L27.5973 10.089C28.1342 10.626 28.1342 11.4966 27.5973 12.0336C27.0603 12.5705 26.1897 12.5705 25.6527 12.0336L21.5277 7.90855C20.9908 7.37158 20.9908 6.50098 21.5277 5.96401Z" fill="#25324B" />
    </g>
    <defs>
      <clipPath id="clip0_204_1786">
        <rect width="33" height="33" fill="white" transform="translate(0.5 0.0612793)" />
      </clipPath>
    </defs>
  </svg>

);
export default EditIcon;
