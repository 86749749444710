import * as React from "react"
const BuildinTwoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    viewBox='0 0 20 21'
    fill="none"
    {...props}
  >
    <g
      stroke="#D3D2D2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#a)"
    >
      <path d="M2.5 17.71h15M4.167 17.71V6.045l6.666-3.333v15M15.833 17.71V9.379l-5-3.334M7.5 7.71v.01M7.5 10.21v.01M7.5 12.71v.01M7.5 15.21v.01" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .21h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default BuildinTwoIcon
