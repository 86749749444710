import axios from "axios";
import { PostDeliverableAttachments } from "./endPoints";

const postDeliverable = async ({ deliverableId, attachment, url }) => {
    const formData = new FormData();
    formData.append('deliverable_id', deliverableId);
    if (attachment) {
        formData.append('attachment', attachment);
    }
    if (url && url.startsWith("https://")) {
        formData.append('url', url);
    }

    try {
        const response = await axios.post(PostDeliverableAttachments, formData);
        return response.data;
    } catch (error) {
        console.error("Error uploading deliverable:", error);
        throw error;
    }
};

export default postDeliverable;



