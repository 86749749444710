import React from 'react';

function VideoIcon({ className }) {

    return (
        <svg
            width={24}
            height={23}
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <mask
                id="a"
                style={{
                    maskType: "alpha",
                }}
                maskUnits="userSpaceOnUse"
                x={0}
                y={0}
                width={24}
                height={23}
            >
                <path fill="#D9D9D9" d="M.4.063h22.8v22.8H.4z" />
            </mask>
            <g mask="url(#a)" fill="#1F2029">
                <path d="M8.95 20.013a.92.92 0 0 1-.676-.274.92.92 0 0 1-.273-.676v-.95H4.2q-.784 0-1.342-.558a1.83 1.83 0 0 1-.558-1.342v-11.4q0-.784.558-1.343A1.83 1.83 0 0 1 4.2 2.913h15.2q.783 0 1.342.557.558.559.558 1.343v11.4q0 .784-.558 1.342-.559.558-1.342.558h-3.8v.95q0 .403-.273.676a.92.92 0 0 1-.678.274zm-4.75-3.8h15.2v-11.4H4.2z" />
                <path d="M9.462 12.817V7.734q0-.57.498-.831a.9.9 0 0 1 .974.047l3.943 2.518q.45.261.45.807 0 .547-.45.808L10.934 13.6a.9.9 0 0 1-.974.048q-.498-.261-.498-.831" />
            </g>
        </svg>
    )
}

export default VideoIcon;