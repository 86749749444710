import React, { useRef, useState } from "react";
import { useOutsideClick } from "../../hooks/useClickOutside";

const Dropdown = ({
  renderOpenButton,
  containerClassName,
  menuClassName,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`relative flex items-center justify-center ${containerClassName}`}
    >
      {renderOpenButton(handleToggle)}
      {isOpen && (
        <ul
          className={`list-none min-w-[7rem] flex flex-col gap-3 absolute top-[3.2rem] right-0 border border-gray-250 rounded-lg shadow py-4 bg-white ${menuClassName}`}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { onClick: handleItemClick })
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
