import axios from 'axios';
import { UserChangePassword } from './endPoints';

const postChangerPassword = async (oldPassword, newPassword, confirmPassword) => {
    try {
        const response = await axios.post(UserChangePassword, {
            old_password: oldPassword,
            new_password: newPassword,
            confirm_password: confirmPassword,
        });
        return response.data;
    } catch (error) {
        console.error('Error changing password:', error);
        throw error;
    }
};

export default postChangerPassword;
