import { Navigate, Route, Routes } from "react-router-dom";
import { pageRoutes } from "../constants";
import DashboardLayout from "./StudentDashboard/DashboardLayout";
import AIReviewer from "./Students/AIReviewer/AIReviewer";
import CourseLayout from "./Students/Course/CourseLayout";
import LessonViewer from "./Students/Course/LessonViewer/LessonViewer";
import DashboardOverview from "./Students/DashboardOverview/DashboardOverview";
import Jobs from "./Students/Jobs/Jobs";
import QA from "./Students/QA/QA";
import Settings from "./Students/Settings/Settings";
import Deliverables from "./Students/Tasks/Tasks";
import Webiners from "./Students/Webiners/Webiners";

const StudentRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={`/${pageRoutes.dashboard}`} />} />
      <Route element={<DashboardLayout />}>
        <Route path={pageRoutes.dashboard} element={<DashboardOverview />} />
        <Route path={pageRoutes.webinars} element={<Webiners />} />
        <Route path={pageRoutes.jobs} element={<Jobs />} />
        <Route path={pageRoutes.deliverables} element={<Deliverables />} />
        <Route path={pageRoutes.course} element={<CourseLayout />}>
          <Route path={`${pageRoutes.lesson}/:id`} element={<LessonViewer />} />
        </Route>
        <Route path={pageRoutes.aiReviewer} element={<AIReviewer />} />
        <Route path={pageRoutes.qa} element={<QA />} />
        <Route path={pageRoutes.settings} element={<Settings />} />
      </Route>
    </Routes>
  );
};

export default StudentRoutes;
