import axios from 'axios';
import { questionEndPoint } from './endPoints';

const postQuestion = async (questionText, categoryId) => {
    try {
        const response = await axios.post(
            questionEndPoint,
            {
                question_text: questionText,
                category: categoryId,
            },
        );
        return response.data;
    } catch (error) {
        console.error("Error posting question:", error);
        throw error;
    }
};

export default postQuestion;
