import React from 'react'

export default function GoodJob({ className }) {
    return (
        <svg
            width={64}
            height={64}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect width={64} height={64} rx={32} fill="#61C478" />
            <path
                d="M27.9995 37.5572L23.3729 32.9305C23.1236 32.6812 22.7854 32.5411 22.4329 32.5411C22.0803 32.5411 21.7422 32.6812 21.4929 32.9305C21.2436 33.1798 21.1035 33.5179 21.1035 33.8705C21.1035 34.0451 21.1379 34.2179 21.2047 34.3792C21.2715 34.5405 21.3694 34.6871 21.4929 34.8105L27.0662 40.3838C27.5862 40.9038 28.4262 40.9038 28.9462 40.3838L43.0529 26.2772C43.3022 26.0279 43.4422 25.6897 43.4422 25.3372C43.4422 24.9846 43.3022 24.6465 43.0529 24.3972C42.8036 24.1479 42.4654 24.0078 42.1129 24.0078C41.7603 24.0078 41.4222 24.1479 41.1729 24.3972L27.9995 37.5572Z"
                fill="white"
            />
        </svg>
    )
}
