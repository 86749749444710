// Student profile
export const UserProfileURL = "/user/profile/";
export const freelanceProfileLinksEndpoint = "/freelance-profile-links/";
export const freelancePlatformsEndpoint = "/platforms/";
// next Webinars
export const NextWebinars = "/webinars/next/";
// QA
export const categoryEndPoint = "/category/";
export const questionEndPoint = "/question/";
// webinar
export const allWebinarsURL = "/webinars/all";
export const allWebinarsFeedbackURL = "/webinar-feedback/";
//Jobs
export const getJobsCategoryEndPoint = "/freelance-category/";
export const postFreelanceJobEndPoint = "/freelance-jobs/";
export const getFreelanceJobMyJobsEndPoint = "/freelance-jobs/";
export const patchStatusMyJobsEndPoint = "/freelance-jobs";
// Course student view
export const CourseHomeEndPoint = "/course/home/";
export const CourseStudentEndPoint = "/course/student/";
export const CompleteLessonEndPoint = "/course/complete-lesson/";

// Settings
export const UserChangeProfilePicture = "/user/change-profile-image/";
export const UserChangePassword = "/user/change-password/";
export const UserRequestSupportEndPoint = "/user/request-support/";

// Deliverables
export const UserDeliverablesStudents = "/deliverable-results/student/";
export const GetDeliverablesById = "/deliverables/";
export const PostDeliverableAttachments = "/deliverable-attachments/";
export const GetDeliverableResultsById = "/deliverable-feedback/";

