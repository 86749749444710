import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import arrow from "../../../../assets/arrow-down.svg";
import { useFreelanceAccounts } from "./useFreelanceAccounts";
const FreelanceAccounts = () => {
  const {
    newPlatform,
    setNewPlatform,
    showInputs,
    setShowInputs,
    postPlatformLink,
    platformId,
    setPlatformId,
    urlError,
    submitError,
    freelancePlatforms,
    freelanceProfileLinks,
    platformLogos,
  } = useFreelanceAccounts();
  const isPlatformIdEmpty = platformId === "";
  return (
    <>
      <button
        onClick={() => setShowInputs(!showInputs)}
        className="my-3 w-full flex items-center gap-1"
      >
        <ControlPointOutlinedIcon className="text-black-12 !w-[1.2rem] !h-[1.2rem]" />
        <span className="block text-sm">Add Freelance Account</span>
      </button>

      {showInputs && (
        <>
          <select
            value={platformId}
            onChange={(e) => setPlatformId(e.target.value)}
            className={`${
              isPlatformIdEmpty ? "opacity-50" : ""
            } w-full text-sm py-2 px-4 bg-white border border-gray-350 rounded-lg appearance-none focus:border-gray-7e bg-no-repeat bg-[95%_center] bg-[length:0.8rem] cursor-pointer`}
            style={{
              backgroundImage: `url(${arrow})`,
            }}
          >
            <option value="">Select Platform</option>
            {freelancePlatforms &&
              freelancePlatforms.map((platform) => (
                <option key={platform.id} value={platform.id}>
                  {platform.name}
                </option>
              ))}
          </select>
          <input
            type="text"
            value={newPlatform}
            onChange={(e) => setNewPlatform(e.target.value)}
            placeholder="Freelance Platform Link"
            className="text-sm mt-3 w-full border border-gray-350 rounded-lg py-2 px-4 focus:border-gray-7e"
          />
          <button
            onClick={postPlatformLink}
            className="btn-primary mt-3 mx-auto"
          >
            Submit Link
          </button>
          {urlError && (
            <>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{urlError}</Alert>
              </Stack>
            </>
          )}
          {submitError && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{submitError}</Alert>
            </Stack>
          )}
        </>
      )}

      <div className="flex flex-wrap items-center self-start gap-2 mt-4">
        {freelanceProfileLinks &&
          freelanceProfileLinks?.map((platform, index) => (
            <a
              key={`freelance-platforms-${index}`}
              className="w-[1.8rem] aspect-square rounded-[50%] overflow-hidden"
              href={platform.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="cover-image"
                src={
                  platformLogos[platform.platform_name] ||
                  platformLogos["Other"]
                }
                alt={platform.platform_name}
                title={platform.platform_name}
              />
            </a>
          ))}
      </div>
      {freelanceProfileLinks && freelanceProfileLinks?.length < 3 && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="warning">You must submit at least 3 links..</Alert>
        </Stack>
      )}
    </>
  );
};

export default FreelanceAccounts;
