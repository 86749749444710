import React from 'react'

export default function LeftArrow({ className }) {
    return (
        <svg
            width={32}
            height={34}
            viewBox="0 0 32 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M16.0556 34C7.24941 34 0.111328 26.5501 0.111328 17.3606C0.111328 8.17124 7.25003 0.721149 16.0556 0.721149C24.8611 0.720833 31.9999 8.17043 31.9999 17.3598C31.9999 26.5491 24.8612 33.9992 16.0556 33.9992V34ZM20.7543 8.07083C20.6267 7.93514 20.4579 7.86715 20.2891 7.86715C20.1245 7.86715 19.9593 7.93199 19.8323 8.06166L11.3652 16.7328C11.2418 16.8593 11.1715 17.032 11.17 17.2129C11.1688 17.3935 11.236 17.5675 11.3576 17.6962L19.8247 26.6515C20.0781 26.9191 20.4903 26.9213 20.7467 26.6579C21.0025 26.3941 21.0055 25.9633 20.7528 25.6957L12.7449 17.2262L20.7458 9.0336C21.0031 8.76982 21.0064 8.33934 20.7546 8.0711L20.7543 8.07083Z"
                fill="#3A4755"
            />
        </svg>
    )
}
