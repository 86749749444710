import React, { createContext, useContext, useState } from "react";
import { CourseStudentEndPoint } from "../../../Services/endPoints";
import useFetch from "../../../hooks/useFetch";

// Create the context
const CourseLayoutContext = createContext(undefined);

// Create a provider component
export const CourseLayoutProvider = ({ children }) => {
  const {
    data: courseData,
    loading: courseLoading,
    error: courseError,
  } = useFetch(CourseStudentEndPoint);

  return (
    <CourseLayoutContext.Provider
      value={{ courseData, courseLoading, courseError }}
    >
      {children}
    </CourseLayoutContext.Provider>
  );
};

// Create a custom hook for easy access to the context
export const useCourseLayoutContext = () => {
  const context = useContext(CourseLayoutContext);
  if (!context) {
    throw new Error(
      "useCourseLayoutContext must be used within a CourseLayoutProvider"
    );
  }
  return context;
};
