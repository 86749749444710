import userImage from "../../../assets/defultUserImage.svg";
import BuildingIcon from "../../../Components/svgs/BuildingIcon";
import PhoneIconSVG from "../../../Components/svgs/PhoneIconeSVG";
const UserInfo = ({ userProfileData, userSystemInfo }) => {
  return (
    <>
      <h2 className="w-fit text-1rem font-medium self-start">Your Profile</h2>
      <div className="w-fit flex flex-col items-center ">
        <div className="w-[6rem] aspect-square rounded-[50%] overflow-hidden">
          <img
            className="cover-image"
            src={userProfileData?.profile_image || userImage}
            alt="user-image"
          />
        </div>
        <h3 className="text-[1rem] text-primary font-semibold mt-2">
          {userProfileData?.full_name}
        </h3>
        <h4 className="text-xs text-gray-7e font-medium mt-1">
          {userProfileData?.track}
        </h4>
        <div className="w-full ps-[1.5rem] flex items-center mt-2 gap-2">
          <PhoneIconSVG className="text-gray-7e" />
          <span className="block text-xs text-gray-5f font-medium">
            {userProfileData?.phone}
          </span>
        </div>
        <div className="w-full ps-[1.5rem]  flex items-center mt-1 gap-2">
          <BuildingIcon className="text-gray-7e" />
          <span className="block text-xs text-gray-5f font-medium">
            {userProfileData?.technical_provider}
          </span>
        </div>
      </div>
      <div className="w-full flex justify-center gap-x-4 gap-y-3 flex-wrap mt-3">
        {userSystemInfo.map((info) => (
          <div
            key={info.name}
            className="relative w-[30%] flex flex-col items-center "
          >
            <h4 className="text-primary text-xs text-center font-semibold mb-2 whitespace-pre-wrap">
              {info.name}
            </h4>
            <span className="block text-gray-5f font-normal text-xs">
              {userProfileData?.[info.fieldName]
                ? userProfileData?.[info.fieldName]
                : "N/A"}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserInfo;
