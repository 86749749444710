import { API_URL } from "./constants";
import { isTokenExpired } from "./isTokenExpired";
import { logout } from "./logout";

export const refreshToken = async (navigate) => {
  const refreshTokenValue = localStorage.getItem("refresh_token");
  if (!refreshTokenValue || isTokenExpired(refreshTokenValue)) {
    logout(navigate); // If refresh token is missing or expired, logout
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ refresh: refreshTokenValue });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  try {
    const response = await fetch(
      `${API_URL}/accounts/token/refresh/`,
      requestOptions
    );
    const result = await response.json();

    if (response.ok) {
      localStorage.setItem("access_token", result.access);
      localStorage.setItem("refresh_token", result.refresh);
    } else {
      console.error("Failed to refresh token:", result);
      logout(navigate); // Logout if refreshing fails
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    logout(navigate); // Logout on any error
  }
};
