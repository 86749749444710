import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import { pageLinks } from "./constants";
import { roleRoutesLookup } from "./constants/roleRoutesLookup";
import { getRole, getToken } from "./Helpers/constants";
import Login from "./NewPages/Common/Login/Login";
import { useEffect } from "react";

function App() {
  const role = getRole();
  const token = getToken();

  if ((!role || !token) && window.location.pathname !== pageLinks.login) {
    window.location.href = `${pageLinks.login}`;
  }

  return (
    <Router>
      <Routes>
        <Route path={pageLinks.login} element={<Login />} />
        <Route path="/*" element={roleRoutesLookup[role]} />
      </Routes>
    </Router>
  );
}

export default App;
