import React from 'react'

export default function RightArrow({ className }) {
    return (
        <svg
            width={32}
            height={34}
            viewBox="0 0 32 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M15.9444 33.2788C24.7506 33.2788 31.8887 25.8289 31.8887 16.6394C31.8887 7.45005 24.75 -4.19617e-05 15.9444 -4.19617e-05C7.13892 -0.000358582 6.10352e-05 7.44923 6.10352e-05 16.6386C6.10352e-05 25.8279 7.13877 33.2788 15.9444 33.2788ZM11.2457 7.34964C11.3733 7.21395 11.5421 7.14595 11.7109 7.14595C11.8755 7.14595 12.0407 7.2108 12.1677 7.34047L20.6348 16.0116C20.7582 16.1381 20.8285 16.3108 20.83 16.4917C20.8312 16.6723 20.764 16.8463 20.6424 16.975L12.1753 25.9304C11.9219 26.1979 11.5097 26.2001 11.2533 25.9367C10.9975 25.6729 10.9945 25.2421 11.2472 24.9745L19.2551 16.505L11.2542 8.31241C10.9969 8.04862 10.9939 7.61788 11.2457 7.34964Z"
                fill="#3A4755"
            />
        </svg>
    )
}
