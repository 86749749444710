import React, { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { GetDeliverablesById } from '../../../Services/endPoints';
import SubmitTask from './SubmitTask';
import GoodJob from '../../../Components/svgs/GoodJob';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import styles from './Tasks.module.css';

function DeliverableDetails({ deliverableId }) {
    const [hasFeedback, setHasFeedback] = useState(false); // Tracks feedback view state

    const {
        data: deliverableDetails,
        loading: detailsLoading,
        error: detailsError,
    } = useFetch(
        deliverableId ? `${GetDeliverablesById}${deliverableId}/` : null
    );

    if (!deliverableId) return null;
    if (detailsLoading) return <p>Loading details...</p>;
    if (detailsError) return <p>Error loading details</p>;

    // Handlers for toggling views
    const handleViewFeedback = () => {
        setHasFeedback(true);
    };

    const handleBackToTask = () => {
        setHasFeedback(false);
    };

    return (
        <div className='border border-[#D9D9D9] rounded-lg p-5 my-10 w-3/4 m-auto'>
            {hasFeedback && deliverableDetails?.submitted === true && deliverableDetails?.has_feedback === true ? (
                // Feedback View
                <div className={styles.taskCard}>
                    <h3>Submit for 10 Freelance Jobs</h3>
                    <p className={styles.description}>
                        <AssignmentIcon /> Within the vision of Egypt 2030 and in light of the constant
                        emphasis of the President of the Republic on the...
                    </p>
                    <p className={styles.deadline}>
                        <CalendarMonthIcon /> Deadline: 9/23/2024, 10:38:56 AM
                    </p>
                    <div className={styles.reviewSection}>
                        <div className={styles.reviewComment}>
                            <h4>Review Comment</h4>
                            <p>
                                Within the vision of Egypt 2030 and in light of the constant
                                emphasis of the President of the Republic on the importance of
                                building the Egyptian human...
                            </p>
                        </div>
                        <div className={styles.reviewDegree}>
                            <h4>Review Degree</h4>
                            <p>100</p>
                        </div>
                    </div>
                    <div className='flex justify-center items-center gap-5 mt-5'>
                        <button className='btn-primary w-40' onClick={handleBackToTask}>
                            Back to Task
                        </button>
                    </div>
                </div>
            ) : (
                // Task Submitted or Submit Task View
                <>
                    <h2 className='text-xl my-4 text-center text-[#202020] font-bold'>
                        {deliverableDetails?.start_week} Deliverables
                    </h2>
                    <h3 className='text-base mt-4 mb-3 text-[#202020] font-semibold'>
                        {deliverableDetails?.task_name}
                    </h3>
                    <p className='text-base text-[#828282] mt-4 whitespace-pre-wrap mb-3'>
                        {deliverableDetails?.description}
                    </p>
                    {deliverableDetails?.submitted === true ? (
                        <div className='text-green-600 font-semibold mt-6'>
                            <div className='flex justify-center items-center flex-col gap-5'>
                                <GoodJob />
                                <h3 className='text-xl my-4 text-center text-[#202020] font-bold'>
                                    Your Task Submitted successfully
                                </h3>
                            </div>
                            {deliverableDetails?.has_feedback === true && (
                                <div className='flex justify-center items-center gap-5 mt-5'>
                                    <button className='btn-primary w-40' onClick={handleViewFeedback}>
                                        View Feedback
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        deliverableDetails?.submitted === false && <SubmitTask deliverableId={deliverableId} />
                    )}
                </>
            )}
        </div>
    );
}

export default DeliverableDetails;
