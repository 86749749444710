import arrowDown from "../../../assets/arrow-down.svg";
import Dropdown from "../../../Components/shared/Dropdown";
import userImage from "../../../assets/defultUserImage.svg";
import UserInfo from "./UserInfo";
const ProfileButton = ({ userProfileImage, toggleMenu }) => {
  return (
    <button className="flex items-center gap-2" onClick={toggleMenu}>
      <div className="w-[2.5rem] circle">
        <img
          className="cover-image"
          src={userProfileImage || userImage}
          alt="default-user-image"
        />
      </div>
      <img className="w-[1rem]" src={arrowDown} alt="" />
    </button>
  );
};
const UserProfileMobileMenu = ({ userProfileData, userSystemInfo }) => {
  return (
    <Dropdown
      renderOpenButton={(toggleMenu) => (
        <ProfileButton
          userProfileImage={userProfileData?.profile_image}
          toggleMenu={toggleMenu}
        />
      )}
      containerClassName="z-[-1]"
      menuClassName="w-[20rem] px-4 items-center"
    >
      <UserInfo
        userProfileData={userProfileData}
        userSystemInfo={userSystemInfo}
      />
    </Dropdown>
  );
};

export default UserProfileMobileMenu;
