import React, { useEffect, useState } from 'react';
import styles from './Settings.module.css';
import useFetch from '../../../hooks/useFetch';
import { UserProfileURL } from '../../../Services/endPoints';
import putChangeProfileImage from '../../../Services/apiPutChangeProfileImage';
import ChangePassword from '../../../Components/ChangePssword/ChangePassword';
import RequestSupport from './RequestSupport/RequestSupport';

function Settings() {
  const [activeTab, setActiveTab] = useState('profilePhoto');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [prevImageUrl, setPrevImageUrl] = useState(null);

  const {
    data: UserProfileData,
    // loading: UserProfileLoading,
    // error: UserProfileError,
  } = useFetch(UserProfileURL);

  // Change profile image
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const validTypes = ['image/jpeg', 'image/png'];

    // Validate file type
    if (file && validTypes.includes(file.type)) {
      setSelectedImage(file);
      setErrorMessage('');
      setUploadSuccess(false);

      // Create a preview URL for the selected image
      const imagePreviewUrl = URL.createObjectURL(file);

      // Revoke previous object URL to prevent memory leaks
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
      setSelectedImagePreview(imagePreviewUrl);
      setPrevImageUrl(imagePreviewUrl);
    } else {
      setErrorMessage('Only JPG and PNG files are allowed');
      setSelectedImage(null);
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
      setSelectedImagePreview(null);
      setPrevImageUrl(null);
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) {
      setErrorMessage('Please select a valid image');
      return;
    }

    try {
      await putChangeProfileImage(selectedImage);
      setUploadSuccess(true);
      setErrorMessage('');
      // Clean up the object URL
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
      // Reload the page to update the profile image
      window.location.reload();
    } catch (error) {
      setErrorMessage('Failed to upload image. Please try again.');
      setUploadSuccess(false);
    }
  };

  // Trigger file input click for choosing another image
  const handleChooseAnotherImage = () => {
    document.getElementById('fileInput').click();
  };

  // Clean up object URL when component unmounts
  useEffect(() => {
    return () => {
      if (prevImageUrl) {
        URL.revokeObjectURL(prevImageUrl);
      }
    };
  }, [prevImageUrl]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profilePhoto':
        return (
          <div className={styles.tabContent}>
            <h2 className='text-[#202020] font-semibold text-left text-lg mb-5'>
              Change profile photo
            </h2>
            <div className='flex px-4 gap-20 items-center mt-10'>
              <div className='w-40 h-40 rounded-full overflow-hidden'>
                <img
                  src={UserProfileData?.profile_image}
                  alt="Profile-Image"
                  className='object-cover w-full h-full'
                />
              </div>

              {/* Upload Box */}
              <div className={styles.uploadBox}>
                {/* Hidden file input */}
                <input
                  type='file'
                  accept='image/jpeg, image/png'
                  onChange={handleImageChange}
                  className='hidden'
                  id='fileInput'
                />

                {/* Upload Placeholder */}
                <div>
                  {selectedImagePreview ? (
                    <div className='flex gap-4 items-center'>
                      {/* Image Preview */}
                      <img
                        src={selectedImagePreview}
                        alt='Selected Profile'
                        className='w-24 h-24 object-cover mb-2'
                      />
                      <div className='flex flex-col gap-2'>
                        {/* Change Profile Image */}
                        <span
                          className='btn-primary'
                          onClick={handleUpload}
                        >
                          Change profile image
                        </span>
                        {/* Choose Another Image */}
                        <span
                          className='btn-primary'
                          onClick={handleChooseAnotherImage}
                        >
                          Choose another image
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* Default Upload Prompt */}
                      <label htmlFor='fileInput' className='cursor-pointer'>
                        <span>Click to replace or drag and drop</span>
                        <p>PNG, JPG (max. 400 x 400px)</p>
                      </label>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Error Message */}
            {errorMessage && (
              <p className='text-red-500 text-sm mt-2 text-center'>{errorMessage}</p>
            )}
          </div>
        );
      case 'resetPassword':
        return (
          <ChangePassword />
        );
      case 'requestSupport':
        return (
          <RequestSupport />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.settingsContainer}>


      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${activeTab === 'profilePhoto' ? styles.active : ''
            }`}
          onClick={() => setActiveTab('profilePhoto')}
        >
          Change profile photo
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'resetPassword' ? styles.active : ''
            }`}
          onClick={() => setActiveTab('resetPassword')}
        >
          Reset Password
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'requestSupport' ? styles.active : ''
            }`}
          onClick={() => setActiveTab('requestSupport')}
        >
          Request Support
        </button>
      </div>

      {renderTabContent()}
    </div>
  );
}

export default Settings;
